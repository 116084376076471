import * as React from 'react';

import * as styles from './styles.module.scss';

const Layout = ({children}) => {
    return (
        <div className={styles.pageWrap}>
            <header className={styles.header}>
                <a href={`/`}>getthesummary.com</a>
            </header>
            <main>
                {children}
            </main>
            <footer className={styles.footer}>
                <BuyMeACoffee />
                <p>© 2024 getthesummary.com</p>
            </footer>
        </div>
    );
};

const BuyMeACoffee = () => {
    return (
        <div className={styles.bmc}>
            <a href="https://www.buymeacoffee.com/samward" title="Buy me a coffee" target="_blank" rel="noreferrer">
                <img src="https://cdn.buymeacoffee.com/buttons/v2/default-black.png" alt="Buy Me A Coffee" />
            </a>
        </div>
    );
}


export default Layout;